import { useEffect, useState } from "react";

function LandingPanel() {
const [backgroundId, setBackgroundId] = useState(1);

    useEffect(()=>{
        const getBackgroundImage = async (min: number, max: number) =>{
            var backgroundId = Math.random() * (max - min) + min;
            setBackgroundId(Math.round(backgroundId));
            
        }

        getBackgroundImage(1,3);
    },[]);
    return (
        <>
         <section className="landing-page" style={{
            backgroundImage: `url("/images/home-bg${backgroundId}.jpg")`
         }}>
        <div className='landing-page-vertical'>
        <div className='landing-page-horizontal'>
          <div className='landing-page-title'>Minhas Vendas</div>
          <div className='landing-page-description'>Tenha total controle de suas vendas através de seu computador, celular ou tablet.</div>
          <div className='landing-page-links'>
          <a href="/#" className="btn btn-outline-primary">Saiba mais</a>
          <a href="/#" className="btn btn-outline-primary">Acessar Painel</a>
          </div>
        </div>
        </div>

      </section>
        </>
    )
}

export default LandingPanel;
function Footer() {
    return (
        <>
            <footer id="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                                <p>Minhas Vendas App - Plataforma Digital para Gerenciamento Comercial &copy; Copyright 2022.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer
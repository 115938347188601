import { Col, Row } from "react-bootstrap";
import stock from "../images/stock.jpg";
import platform from "../images/platform.jpg";
import client from "../images/client.jpg";

function AboutPanel() {
    return(
        <>
        <div className="about-panel">
            <Row>
                <Col xs={5}>
                <img className="img-platform" src={platform} width={300} height={260} ></img>
                <p className="txt-platform">Organize e gerencie seu estoque de maneira prática e totalmente digital.<br/>
                Deixe planilhas e a papelada no passado, comece a gerenciar seu estoque através de nossa plataforma. Nossa plataforma pode te ajudar a ter pleno controle de seu estoque.</p>
                </Col>
                <Col xs={2}></Col>
                <Col xs={5}>
                <img className="img-stock" src={stock} width={300} height={260} ></img>
                <p className="txt-stock">Organize e gerencie seu estoque de maneira prática e totalmente digital.<br/><br/>
                Deixe planilhas e a papelada no passado, comece a gerenciar seu estoque através de nossa plataforma. Nossa plataforma pode te ajudar a ter pleno controle de seu estoque.</p>
                </Col>
            </Row>
            <Row>
                <Col xs={4}></Col>
                <Col xs={8}>
                <img className="img-client" src={client} width={300} height={260} ></img>
                <p className="txt-stock">Organize e gerencie seu estoque de maneira prática e totalmente digital.<br/><br/>
                Deixe planilhas e a papelada no passado, comece a gerenciar seu estoque através de nossa plataforma. Nossa plataforma pode te ajudar a ter pleno controle de seu estoque.</p>
                </Col>
                
            </Row>
            <div>
                
            </div>
        
        </div>
        </>
    )
}

export default AboutPanel;
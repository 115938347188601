import React from 'react';
import { Button } from 'react-bootstrap';
import './App.css';
import AboutPanel from './components/about';
import Footer from './components/footer';
import LandingPanel from './components/landing';



function App() {
  return (
    <div className='page'>
     <LandingPanel/>
     <AboutPanel/>
     <Footer/>
    </div>
  );
}

export default App;
